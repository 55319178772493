<template>
  <loading
    v-model:active="isLoading"
    :can-cancel="true"
    :on-cancel="onCancel"
    :is-full-page="fullPage"
  />
  <div class="dash-title">
    <div class="title">My Business</div>
    <div class="filter">
      <div>
        <input type="date" />
      </div>

      <div>
        <select class="form-select" aria-label="Default select example">
          <option value="1">One</option>
          <option value="2">Two</option>
          <option value="3">Three</option>
        </select>
      </div>
    </div>
  </div>
  <!-- items -->
  <template v-if="recentOrders.length > 0">
    <div class="items-container">
      <table style="width: 100%">
        <tr>
          <td id="header">Order</td>
          <td id="header">Date</td>
          <td id="header">Quantity</td>
          <td id="header">Total Price</td>
          <td id="header">Rider</td>
          <td id="header">Action</td>
        </tr>
        <tr
          class="items-label"
          v-for="(orders, index) in recentOrders"
          :key="index"
        >
          <td>
            <div class="f-details">
              <img :src="food" alt="" />
              <div class="food_type">
                <div class="food-name">{{ orders?.name }}</div>
              </div>
            </div>
          </td>
          <td>
            <div>
              <div class="food-name">{{ orders?.formatedDate }}</div>
            </div>
          </td>
          <td>
            <div class="qty">{{ orders?.quantity }}</div>
          </td>
          <td>
            <div class="food-name">{{ orders?.price }}</div>
          </td>
          <td>
            <div class="food-name">Okoro</div>
          </td>
          <td>
            <div class="button">
              <div class="view" @click="openOrderDetails(orders)">
                <button>View</button>
              </div>
              <div class="accept" @click="acceptOrder(orders)">
                <button>Accept</button>
              </div>
              <div class="cancel"><img :src="cancel" alt="" /></div>
            </div>
          </td>
        </tr>
      </table>

      <!-- Order details modal -->
      <div v-if="modal">
        <transition name="model">
          <div class="modal-mask">
            <div class="modal-wrapper">
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-header">
                    <div class="header-title">
                      <div>Order Status</div>
                      <div
                        class="close"
                        @click="modal = false"
                        aria-hidden="true"
                      >
                        &times;
                      </div>
                    </div>
                  </div>
                  <div class="modal-body">
                    <div class="row">
                      <div class="col-md-8">
                        <div class="c-container">
                          <div><img :src="orderDetails" alt="" /></div>
                          <div class="order-food-details">
                            <div class="food-order-name">
                              {{ singleOrderDetails?.name }}
                            </div>
                            <!-- <div class="other-name">Chicken</div> -->
                            <div class="order-quantity">
                              <div class="order-qty">
                                Qty - {{ singleOrderDetails?.quantity }}
                              </div>
                              <div class="order-price">
                                NGN {{ singleOrderDetails?.price }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="buttons-actions">
                          <div>
                            <div class="accept">
                              <button @click="acceptOrder(singleOrderDetails)">
                                Accept
                              </button>
                            </div>
                          </div>
                          <div>
                            <div class="decline">
                              <button @click="declineOrder(singleOrderDetails)">
                                Decline
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- modal body content 2 -->
                    <div class="row mt-4">
                      <div class="col-md-6">
                        <div class="tracker1-container">
                          <div>
                            <div>
                              <img :src="tracker" alt="" />
                            </div>
                            <div style="padding-left: 17px">
                              <img :src="line2" alt="" />
                            </div>
                          </div>
                          <div class="tracker-details">
                            <div class="orderStatus1">order received</div>
                            <div class="orderStatus2">
                              Your order has been received
                            </div>
                            <div class="timer">03:25pm</div>
                          </div>
                        </div>
                        <div class="tracker1-container">
                          <div>
                            <div>
                              <img :src="way" alt="" />
                            </div>
                            <div style="padding-left: 17px">
                              <img :src="line2" alt="" />
                            </div>
                          </div>
                          <div class="tracker-details">
                            <div class="orderStatus1">order processed</div>
                            <div class="orderStatus2">Order prepared</div>
                            <div class="timer">03:25pm</div>
                          </div>
                        </div>

                        <div class="tracker1-container">
                          <div>
                            <div>
                              <img :src="delivered" alt="" />
                            </div>
                            <div style="padding-left: 17px">
                              <img :src="line2" alt="" />
                            </div>
                          </div>
                          <div class="tracker-details">
                            <div class="orderStatus1">Delivery in Progress</div>
                            <div class="orderStatus2">Order on the way</div>
                            <div class="timer">03:25pm</div>
                          </div>
                        </div>

                        <div class="tracker1-container">
                          <div>
                            <div>
                              <img :src="confirm" alt="" />
                            </div>
                          </div>
                          <div class="tracker-details">
                            <div class="orderStatus1">Order Delivered</div>
                            <div class="orderStatus2">
                              Order has been received
                            </div>
                            <div class="timer">03:25pm</div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div>
                          <select
                            class="changeStatus"
                            aria-label="Default select example"
                            @change="onChange($event, singleOrderDetails)"
                          >
                            <option>--Change Status--</option>
                            <option value="ACKNOWLEDGED">Order Received</option>
                            <option value="PROCESSING">order processed</option>
                            <option value="DISPATCHED">
                              Delivery in Progress
                            </option>
                            <option value="DELIVERED">Order Delivered</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </template>
  <template v-else>
    <div class="text-center my-5 py-5 text-muted">
        <div class="my-5 py-5">
          <div class="fs-2"><i class="fa-solid fa-face-sad-tear"></i></div>
          <div class="mt-2">Your order list is empty</div>
        </div>
      </div>
  </template>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Loading from "vue-loading-overlay";

export default {
  data() {
    return {
      food: require("../../../../assets/food_img.png"),
      cancel: require("../../../../assets/cancel.png"),
      orderDetails: require("../../../../assets/order_details.png"),
      tracker: require("../../../../assets/tracker1.png"),
      line2: require("../../../../assets/Line2.png"),
      way: require("../../../../assets/Onway.png"),
      delivered: require("../../../../assets/Delivered.png"),
      confirm: require("../../../../assets/Confirm.png"),
      modal: false,
      recentOrders: "",
      singleOrderDetails: "",
      isLoading: false,
      fullPage: true,
      acceptStatus: "ACKNOWLEDGED",
      declineStatus: "DECLINED",
    };
  },

  components: {
    Loading,
  },

  methods: {
    ...mapActions({
      changeOrderStatus: "order/updateOrder",
    }),

    ...mapGetters({
      availableOrders: "vendor/VendorOrders",
    }),

    openOrderDetails(orders) {
      this.singleOrderDetails = orders;
      this.modal = true;
    },

    acceptOrder(order) {
      this.isLoading = true;
      let data = {
        order_id: order.order_id,
        status: this.acceptStatus,
      };
      this.changeOrderStatus(data).then((data) => {
        if (data == undefined) {
          this.isLoading = false;
        } else {
          this.isLoading = false;
        }
      });
    },

    declineOrder(order) {
      this.isLoading = true;
      let data = {
        order_id: order.order_id,
        status: this.declineStatus,
      };
      this.changeOrderStatus(data).then((data) => {
        this.modal = false;
        if (data == undefined) {
          this.isLoading = false;
        } else {
          this.isLoading = false;
        }
      });
    },

    onChange(e, order) {
      this.isLoading = true;
      let data = {
        order_id: order.order_id,
        status: e.target.value,
      };
      this.changeOrderStatus(data).then((data) => {
        this.modal = false;
        if (data == undefined) {
          this.isLoading = false;
        } else {
          this.isLoading = false;
        }
      });
    },
  },

  async created() {
    let orders = this.$store.state["vendor"].vendorOrders;
    let recentOrders = [];
    for (let i = 0; i < orders.length; i++) {
      orders = orders.map((data) => {
        return {
          ...data,
          formatedDate: data.created_at.split("T")[0],
        };
      });
    }
    recentOrders = [...recentOrders, ...orders];
    this.recentOrders = recentOrders;
  },
};
</script>

<style scoped>
.title {
  color: rgba(4, 3, 15, 1);
  font-weight: 600;
  font-size: 15px 20px;
}

.qty {
  color: #43424c;
  padding-left: 10px;
}

#header {
  font-size: 13px;
  color: rgba(67, 66, 76, 1);
}

.cancel img {
  padding: 5px;
  background-color: rgba(217, 217, 217, 1);
  border-radius: 50%;
}

.view button {
  border: 1px solid rgba(133, 77, 39, 1);
  background-color: #fff;
  margin-right: 10px;
  color: rgba(133, 77, 39, 1);
  border-radius: 5px;
  padding: 3px 9px;
  /* font-size:20px; */
}

.accept button {
  border: none;
  background-color: rgba(133, 77, 39, 1);
  margin-right: 10px;
  color: #fff;
  border-radius: 5px;
  padding: 3px 9px;
  /* font-size:20px; */
}

.decline button {
  border: none;
  background-color: rgba(67, 66, 76, 1);
  margin-right: 10px;
  color: #fff;
  border-radius: 5px;
  padding: 3px 9px;
}

#food-transact {
  display: flex;
  justify-content: space-between;
}

.combo {
  color: rgba(67, 66, 76, 1);
  font-size: 10px;
}

.food-name {
  color: rgba(67, 66, 76, 1);
  font-size: 12px;
  font-weight: 600;
}

.f-details {
  display: flex;
  padding: 10px;
}

.button {
  display: flex;
}

.food_type {
  margin-left: 10px;
}

.items-label {
  background: #ffffff;
  box-shadow: 0px 2px 50px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
}

.order {
  margin-right: 10%;
}

.filter {
  display: flex;
}

.items-container {
  margin: 0px 45px;
  margin-top: 20px;
}

.other-label {
  display: flex;
  justify-content: space-around;
}

input {
  outline: none;
  border: none;
  box-shadow: none;
  color: black;
  padding: 2px 15px;
  margin-right: 10px;
  /* background: #FFFFFF; */
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
}

.header-label {
  display: flex;
  justify-content: space-around;
  color: rgba(67, 66, 76, 1);
  font-size: 15px;
  margin-top: 3%;
}

.form-select {
  outline: none;
  border: none;
  box-shadow: none;
  color: black;
  padding: 2px 30px;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
}

.changeStatus {
  outline: none;
  border: none;
  box-shadow: none;
  color: black;
  padding: 2px 20px;
  /* margin-right: 20px; */
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
}

.dash-title {
  display: flex;
  justify-content: space-between;
  margin-top: 3%;
  margin-left: 5%;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: all 0.5s ease-in-out;
}

.modal-wapper {
  display: table-cell;
  vertical-align: middle;
}

.header-title {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.close {
  cursor: pointer;
}

.c-container {
  display: flex;
}

.food-order-name {
  font-size: 15px;
  color: #04030f;
  font-weight: 600;
}

.other-name {
  font-size: 10px;
  color: #43424c;
  font-weight: 600;
}

.order-quantity {
  display: flex;
  color: #04030f;
  font-weight: 580;
}

.buttons-actions {
  display: flex;
}

.order-food-details {
  margin-left: 10px;
}

.order-qty {
  margin-right: 5%;
  font-size: 12px;
}

.order-price {
  font-size: 12px;
}

.tracker1-container {
  display: flex;
}

.orderStatus1 {
  color: rgba(4, 3, 15, 1);
  font-size: 15px;
  font-weight: 600;
}
.orderStatus2 {
  color: rgba(122, 122, 122, 1);
  font-size: 10px;
}

.tracker-details {
  padding-left: 10px;
}

.timer {
  color: rgba(67, 66, 76, 1);
  font-size: 10px;
}
</style>
